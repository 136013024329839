.message-list{
    flex: 1;
    min-width: 140px;
    overflow: scroll;
    scroll-behavior: smooth;
    background-color: #0AB591;
}
.chat-list {
    height: 100%;
    /* works because the width set for chat list is 1/3 (flex 1 vs flex 2) */
    max-width: 33.3vw; 
    /* min-width: 240px;
    max-width: 380px;
    height: 80vh;
    overflow-y: scroll;
    margin: 0; */
    border: 1px solid rgb(238, 235, 235);
}
.chat-selected{
    font-weight: bold;
    color: #0AB591;
}
  .rce-example-input {
  border: 1px solid rgb(214, 213, 213);
}
.chat-icon{
    height: none;
    width: none;
    margin-right: 10px;

}
/* .chat-send-button{
    opacity: 0.8;
    background-color: "white",

}
.chat-send-button:hover{
    opacity: 1;
    background-color: #0AB591;
} */