/* .accordion{
    --bs-accordion-bg: none !important;
} */
.accordion{
    --bs-accordion-bg: none !important;
    --bs-accordion-active-bg: none !important;
    --bs-accordion-active-color: none !important;
    --bs-accordion-btn-color: none !important;
    --white-arrow: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
    --bs-accordion-btn-icon: var(--white-arrow) !important;
    --bs-accordion-btn-active-icon: var(--white-arrow) !important;
    /* color: #ffffff; */
    /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}
.accordion-header{
    text-align: center;
}
.accordion-button:after{
    /* background-color: red; */
    /* margin: 0 !important; */
}
.accordion-button{
    display:flex !important;
    flex: 1;
    /* align-items: center; */
    /* justify-content: center; */
    /* align-items: center !important; */
    margin-left: auto;
    margin-right: auto;
    font: inherit !important;
    text-align: center !important;

}
/* ul{
    list-style: "🤯";
    padding-inline-start: 10px;
} */
ul {
    /* list-style: lower-roman url("../../assets/images/xplain_logo_png_3.png") outside; */
    /* list-style: url("../../assets/images/banner.png");  */

     /* list-style-image: url("../../assets/images/xplain_logo_png_2.png");  */
} 
li.TutorPage-Description:before { 
    content: url("../../assets/images/xplain_logo_png_3.png");
    transform: scale(1.25);
    /* height: 10px;
    width: 20px;    
    display: block; */

    margin-left: -10px; 
    margin-right: 10px; 
    list-style-type: 'none';
} 